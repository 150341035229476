<template>
  <div class="header" id="top_header">
    <div class="logo">
      <router-link :to="{ name: 'Home' }">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="99"
          height="30"
          viewBox="0 0 125 42"
          fill="none"
        >
          <rect width="124.646" height="41.1393" fill="url(#pattern0)" />
          <defs>
            <pattern
              id="pattern0"
              patternContentUnits="objectBoundingBox"
              width="1"
              height="1"
            >
              <use
                xlink:href="#image0_5030:14655"
                transform="translate(0 -0.000337451) scale(0.000353232 0.00107024)"
              />
            </pattern>
            <image
              id="image0_5030:14655"
              width="2831"
              height="935"
              :xlink:href="relsifyLogo"
            />
          </defs>
        </svg>
      </router-link>
    </div>
    <div class="relsify-nav" :class="showMobileMenu && 'showMenu'">
      <div class="menu_close">
        <i class="bx bx-x" @click="toggleMenu"></i>
      </div>
      <div class="menu_logo">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="99"
          height="30"
          viewBox="0 0 125 42"
          fill="none"
        >
          <rect width="124.646" height="41.1393" fill="url(#pattern0)" />
          <defs>
            <pattern
              id="pattern0"
              patternContentUnits="objectBoundingBox"
              width="1"
              height="1"
            >
              <use
                xlink:href="#image0_5030:14655"
                transform="translate(0 -0.000337451) scale(0.000353232 0.00107024)"
              />
            </pattern>
            <image
              id="image0_5030:14655"
              width="2831"
              height="935"
              :xlink:href="relsifyLogo"
            />
          </defs>
        </svg>
      </div>
      <!-- <router-link :to="{ name: 'Home' }">Home</router-link> -->
      <router-link :to="{ name: 'About' }">About Relsify</router-link>
      <router-link :to="{ name: 'ExploreMarketPlace' }"
        >Explore marketplace</router-link
      >
      <!-- <router-link :to="{ name: 'TokenizeProperty' }"
        >Tokenize your property</router-link
      > -->

      <!-- <a
        href="javascript:;"
        class="small-menu-logout"
        @click="logout"
        v-if="user"
        >Logout <i class="bx bx-power-off"></i
      ></a> -->
    </div>
    <div class="relsify_nav_button">
      <!-- <a href="javascript:;" class="login_button" @click="login" v-if="!user"
        >Login</a
      >
      <a href="javascript:;" class="sign_button" v-if="!user">Sign up</a>
      <div class="loggedin_user" v-if="user" @click="toggeleDropdown">
        <img :src="user.image.url" v-if="user.image.url" alt="" />
        <img src="@/assets/img/vectors/user-icon.png" v-else alt="" />
        <span>{{ user.firstName }}</span>
        <i class="bx bx-chevron-right"></i>
        <i class="bx bx-chevron-down" v-if="showDropdown"></i>
      </div>

      <a href="javascript:;" class="logout_btn" @click="logout" v-if="user">
        Logout <i class="bx bx-power-off"></i
      ></a> -->

      <div class="burger-menu">
        <i class="bx bx-menu" @click="toggleMenu"></i>
      </div>
    </div>
    <div class="loggedin_user_dropdown">
      <div class="loggedin_user_dropdown_menu">
        <a :href="DASHBOARD_URL" target="_blank">
          <img src="@/assets/img/vectors/home-icon.png" alt="" />
          <span>Overview </span>
        </a>
        <a :href="DASHBOARD_URL + '/wallet'" target="_blank">
          <img src="@/assets/img/vectors/wallet.png" alt="" />
          <span>Wallet </span>
        </a>
        <a :href="DASHBOARD_URL + '/trade'" target="_blank">
          <img src="@/assets/img/vectors/trading.png" alt="" />
          <span>Trade </span>
        </a>
        <a :href="DASHBOARD_URL + '/portfolio'" target="_blank">
          <img src="@/assets/img/vectors/investment.png" alt="" />
          <span>Portfolio </span>
        </a>
        <a :href="DASHBOARD_URL + '/settings'" target="_blank">
          <img src="@/assets/img/vectors/setting.png" alt="" />
          <span>Settings </span>
        </a>

        <a href="javascript:;" @click="$router.push({ name: 'HelpCenter' })">
          <img src="@/assets/img/vectors/male-marketer.png" alt="" />
          <span>Help & Support </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import $ from "jquery";
export default {
  computed: {
    ...mapGetters(["relsifyLogo"]),
    showMobileMenu: {
      get() {
        return this.$store.state.showMobileMenu;
      },

      set(value) {
        return (this.$store.state.showMobileMenu = value);
      },
    },
  },
  data() {
    return {
      isLoggedIn: false,
      showDropdown: false,
    };
  },

  methods: {
    ...mapActions([
      "setMobileMenu",
      "setLoginModal",
      "setPageLoading",
      "closeMobileMenu",
    ]),
    login: function () {
      this.setLoginModal({
        status: true,
        payload: null,
      });
    },
    logout: function () {
      this.setPageLoading(true);
      this.closeMobileMenu();
      setTimeout(() => {
        // this.setPageLoading(false);
        this.logoutUser();
      }, 2000);
    },
    toggeleDropdown: function () {
      if ($(".loggedin_user_dropdown").hasClass("showMenuDropDown")) {
        // $(".loggedin_user_dropdown").hide();
        $(".loggedin_user").removeClass("showMenuDropDown");
        $(".loggedin_user_dropdown").removeClass("showMenuDropDown");
      } else {
        setTimeout(() => {
          $(".loggedin_user_dropdown").addClass("showMenuDropDown");
          $(".loggedin_user").addClass("showMenuDropDown");
        }, 300);
      }
    },

    toggleMenu: function () {
      console.log("here");
      this.setMobileMenu();
    },
  },
};
</script>

<style></style>
