<template>
    <div class="alert-modal verifypinModal" v-if="investmentRequestModal.status">
      <div class="alert-modal-overlay" @click="closeInvestmentRequest"></div>
      <ModalLoading />
      <div class="alert-modal-card vivify popInBottom">
        <div class="close-alert-button">
          <i class="bx bx-x" id="closeAlertModal" @click="closeInvestmentRequest"></i>
        </div>
  
        <div class="alert-modal-body">
          <iframe v-if="selectedCategory === 'Relsify_Realty'" id="iframeEle"  src="https://docs.google.com/forms/d/e/1FAIpQLSev8hHC0cEW7nb11MGiHvSCZ2hqmoY-eQuWiEbw62zOQQCpuQ/viewform?embedded=true" width="640" height="1604" frameborder="0" marginheight="0" marginwidth="0"></iframe>
          <iframe v-if="selectedCategory === 'Shortletify'" id="iframeEle"  src="https://docs.google.com/forms/d/e/1FAIpQLSdX9y1CbAcYG2skyQxZCuBTKFfMoUr5mODIV8V1ydS_Qw7BJA/viewform?embedded=true" width="640" height="1755" frameborder="0" marginheight="0" marginwidth="0"></iframe>
          <iframe v-if="selectedCategory === 'Vestify'" id="iframeEle"  src="https://docs.google.com/forms/d/e/1FAIpQLSdowa3wtut2PuaKMpfKEoMdNY6_bAuh19d2TlxSNo0k80_JWA/viewform?embedded=true" width="640" height="1816" frameborder="0" marginheight="0" marginwidth="0"></iframe>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import "@/mixins";
  import "@/assets/css/modal.css";
  import ModalLoading from "./ModalLoading.vue";
  import { ref } from "vue";
  export default {
    components: {
      ModalLoading,
    },
    setup() {
      const iframeEle = ref(null)

      return {
        iframeEle
      }
    },
    // data() {
    //   return {
    //     iframeEle: document.getElementById('investmentIframe')
    //   }
    // },
    computed: {
      investmentRequestModal: {
        get() {
          return this.$store.state.investmentRequestModal
        },
        set(value) {
          return (this.$store.state.investmentRequestModal = value)
        }
      },
      selectedCategory: {
        get() {
          return this.$store.state.selectedCategory;
        },
        set(value) {
          return (this.$store.state.selectedCategory = value);
        },
      },
      modalLoading: {
        get() {
          return this.$store.state.modalLoading
        },
        set (value) {
          return (this.$store.state.modalLoading = value)
        }
      }
    },
    methods: {
      closeInvestmentRequest() {
        this.investmentRequestModal = {
          status: false,
          payload: null
        }
      }
    },
    watch: {
      investmentRequestModal () {
        if (this.investmentRequestModal.status === true) {
          setTimeout(() => {
            document.getElementById('iframeEle')?.addEventListener('load', () => {
              this.modalLoading = false
            }, true)
          }, 0)
        }
      }
    },
    mounted () {
      this.modalLoading = true
    }
  };
  </script>
  
  <style scoped>
  .alert-modal-card {
    width: 80vw;
    height: auto;
  }
  
  .instructionMessageInputCodes {
    width: 100%;
  }
  
  .instructionMessageInputCodes input {
    width: 50px;
    height: 39px;
    background: rgba(24, 133, 111, 0.05);
    border-radius: 171.051px;
  }
  
  .alert-modal-body iframe {
    width: 100%;
    height: 80vh;
  }
  .alert-modal-body h5 {
    text-align: center;
    margin-top: 40px;
    font-weight: 500;
    font-size: 19px;
  }
  
  .resendCode {
    margin-top: 30px;
    margin-bottom: 20px;
  }
  
  .resendCode a {
    font-weight: bold;
    text-decoration: none;
    font-size: 13px;
  }
  
  .modalLoading {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    background: rgba(231, 231, 231, 0.73);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden !important;
    z-index: 9980 !important;
    border-radius: 30px;
  }
  
  @media screen and (max-width: 580px) {
    .alert-modal-card {
      width: 98%;
    }
  }
  </style>
  