<template>
  <div>
    <div ref="productSlide" class="productSlide">
      <transition-group v-if="isVisible" appear mode="out-in" @before-enter="beforeEnter" @enter="enter">
          <ProductItem
          v-for="(project, index)  in projects"
          :data-index="index + 1"
          :key="index"
          :project="project"
        />
      </transition-group>
    </div>
    <div class="text-center">
      <ProjectInfinitescrolltrigger />
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import { ref } from 'vue';
import ProductItem from "./ProductItem.vue";
import ProjectInfinitescrolltrigger from "./ProjectInfinitescrolltrigger.vue";
export default {
  props: ["projects"],
  components: {
    ProductItem,
    ProjectInfinitescrolltrigger,
  },
  setup() {
    const productSlide = ref(null)
    const isVisible = ref(false)
    const observer = new IntersectionObserver(
      ([entry]) => {
        isVisible.value = entry.isIntersecting;
      }
    );
    return {
      productSlide,
      isVisible,
      observer
    }
  },
  computed: {

  },
  methods: {
    beforeEnter (el) {
      el.style.opacity = 0
      el.style.transform = 'translate3d(0, 100%, 0)'
    },
    enter(el, done) {
      gsap.to(el, {
        opacity: 1,
        y: 0,
        duration: .5,
        onComplete: done,
        delay: el.dataset.index * 0.1
      })
    },
    isScrolledIntoView(el) {
      const rect = el.getBoundingClientRect()
      const elemTop = rect.top
      const elemBottom = rect.bottom
      this.isVisible = elemTop < window.innerHeight && elemBottom >= 0
      return this.isVisible
    },
    handleScroll() {
      /* if scrolled into view */
      this.isScrolledIntoView(this.productSlide)
    }
  },
  mounted() {
    this.observer.observe(this.productSlide);
  }
};
</script>

<style scoped>
@media(max-width: 650px) {
  .productSlide {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
