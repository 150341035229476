<template>
  <div class="product-card" v-if="project">
    <div class="product-card-img">
      <div
        v-if="project?.mainImage?.url"
        :class="project?.mainImage?.url"
        :style="{
          'background-color': '#b9bcc8',
          'background-size': 'cover',
          'background-repeat': 'no-repeat',
          'background-position': 'center'
        }"
				>
      </div>
      <!-- <a
        href="javascript:;"
        @click="filterProduct(project?.category)"
        class="product-card-category"
        >{{ project?.category }}</a
      > -->
    </div>
    <div class="product-card-description">
      <div class="product-card-description-header">
        <h3
          @click="
            $router.push({
              name: 'ProjectDetails',
              query: { id: project?.id },
            })
          "
          style="cursor: pointer"
        >
          {{ project?.title }}
        </h3>
        <p>
          {{ project?.description }}
          <!-- <span
            v-if="project?.description?.length > 75"
            @click="
              $router.push({
                name: 'ProjectDetails',
                query: { id: project?.id },
              })
            "
          >
            ...<b>Read More</b>
          </span> -->
        </p>
      </div>
      <hr />

      <div class="product-card-description-details">
        <div class="product-card-description-details-item ">
          <p v-if="['Relsify_Realty', 'Shortletify', 'Vestify'].includes(selectedCategory)">
            <span class="product-card-description-details-item-title"
              >Type</span
            >
            <span class="product-card-description-details-item-prc green-color"
              >{{ project?.type }}</span
            >
          </p>
          <p v-if="['Relsify_Realty', 'Shortletify', 'Vestify'].includes(selectedCategory)">
            <span class="product-card-description-details-item-title"
              >Location</span
            >
            <span class="product-card-description-details-item-prc green-color"
              >
              <!-- <img
                v-if="countryFlag"
                :src="countryFlag"
                alt=""
                style="
                  margin-right: 2px;
                  border-radius: 100%;
                  width: 14px;
                  height: 14px;
                "
              /> -->
              <span>{{ project?.location }}</span></span
            >
          </p>
        </div>
        <div class="product-card-description-details-item">
           <p v-if="['Relsify_Realty', 'Shortletify', 'Vestify'].includes(selectedCategory)">
            <span class="product-card-description-details-item-title"
              >Price</span
            >
            <span class="product-card-description-details-item-prc green-color"
              >{{ project?.price }}</span
            >
          </p>
          <p v-if="['Vestify'].includes(selectedCategory)">
            <span class="product-card-description-details-item-title"
              >Total Raised</span
            >
            <span class="product-card-description-details-item-prc green-color"
              >{{ project?.target_raise }}</span
            >
          </p>
          <p v-if="['Vestify'].includes(selectedCategory)">
            <span class="product-card-description-details-item-title"
              >Minimum Entry</span
            >
            <span class="product-card-description-details-item-prc green-color"
              >{{ project?.min_entry }}</span
            >
          </p>
          <p v-if="['Shortletify'].includes(selectedCategory)">
            <span class="product-card-description-details-item-title"
              >Caution</span
            >
            <span class="product-card-description-details-item-prc green-color"
              >{{ project?.caution }}</span
            >
          </p>
          <div v-if="['Relsify_Realty', 'Shortletify', 'Vestify'].includes(selectedCategory)">
            <span class="product-card-description-details-item-title"
              >Features: </span
            >
            <span  class="product-card-description-details-item-prc green-color"
              ><ul>
                <li v-for="(feature, index) in project?.features" :key="index">{{ feature }}</li>
              </ul></span
            >
            </div>
        </div>
      </div>
      <div class="product-button">
        <button class="relsify-button" @click="requestInvestment(project)">
          <span>{{ project?.CTA }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// import "@/mixins";
import { mapActions } from "vuex";
export default {
  props: ["project"],
  // setup() {
  //   function getImageUrl(name){ 
  //     new URL(`../assets/images/${name}`, import.meta.url).href
  //   }

  //   return {
  //     getImageUrl
  //   }
  // },
  computed: {
    countries() {
      return this.$store.state.countries;
    },

    selectedCategory: {
      get() {
        return this.$store.state.selectedCategory;
      },
      set(value) {
        return (this.$store.state.selectedCategory = value);
      },
    },
    modalLoading: {
      get() {
        return this.$store.state.modalLoading
      },
      set (value) {
        return (this.$store.state.modalLoading = value)
      }
    },

    countryFlag() {
      let flag = "";
      if (this.countries && this.project) {
        let countryFlag = this.countries.find(
          (flag) =>
            flag.name.toLowerCase() == this.project?.country.toLowerCase()
        );
        if (countryFlag) {
          flag = countryFlag.flag;
        }
      }

      return flag;
    },
  },
  methods: {
    ...mapActions(["setCurrentProject", "filterProjects", "setLoginModal", "setInvestmentRequestModal"]),

    buyShares: function (project) {
      if (!this.user) {
        this.setLoginModal({
          status: true,
          payload: null,
        });

        return;
      }

      let url = `${this.DASHBOARD_URL}/checkout?id=${project?.id}`;
      var a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("target", "_blank");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      // $router.push({ name: 'ProjectDetails' })
    },
    requestInvestment(project) {
      this.modalLoading = true
      this.setInvestmentRequestModal({
        status: true,
        payload: project
      })
    },

    filterProduct: function (category) {
      if (this.selectedCategory == category) {
        return;
      }
      this.selectedCategory = category;
      let url = `${
        this.RELSIFY_URL
      }/api/projects?page=${1}&limit=${20}&category=${category}`;
      this.filterProjects(url);
    },
  },
};
</script>

<style scoped>
.product-card {
  position: relative;
  background: #fff;
  color: #7a7a7a;
  border-radius: 20px;
  /* min-width: 320px !important; */
  width: 100% !important;
  position: relative;
  margin-right: 20px;
  box-shadow: 0px 6px 8px 8px rgba(189, 189, 189, 0.22);
}

.product-card .product-card-img {
  position: relative;
}

.product-card .product-card-img div {
  width: 100%;
  height: 120px;
  border-radius: 20px;
  object-fit: cover;
}

.relsify_realty_1 {
  background-image: url('../../../assets/img/relsify_realty_1.jpg');
}

.relsify_realty_2 {
  background-image: url('../../../assets/img/relsify_realty_2.jpg');
}

.relsify_realty_3 {
  background-image: url('../../../assets/img/relsify_realty_3.jpg');
}

.relsify_realty_4 {
  background-image: url('../../../assets/img/relsify_realty_4.jpg');
}

.shortlet_1 {
  background-image: url('../../../assets/img/shortlet_1.jpg');
}

.shortlet_2 {
  background-image: url('../../../assets/img/shortlet_2.jpg');
}

.shortlet_3 {
  background-image: url('../../../assets/img/shortlet_3.jpg');
}

.shortlet_4 {
  background-image: url('../../../assets/img/shortlet_4.jpg');
}

.vestify_1 {
  background-image: url('../../../assets/img/vestify_1.jpg');
}

.vestify_2 {
  background-image: url('../../../assets/img/vestify_2.jpg');
}

.product-card .product-card-img .product-card-category {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  background: #ffffff;
  color: var(--mainGreenColor);
  border-radius: 20px;
  padding: 5px 12px;
  font-size: 11px;
  margin-top: 12px;
  margin-right: 12px;
  text-decoration: none;
  transition: all 300ms ease-in-out;
}

.product-card .product-card-img .product-card-category:hover {
  background: var(--mainYellowColor);
  color: #444;
}

.product-card-description-header {
  padding-left: 5px;
  padding-right: 5px;
} 
.product-card-description-header p {
  text-transform: uppercase;
}

.product-card .product-card-description {
  padding: 20px 14px;
}

.product-card .product-card-description h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: var(--mainGreenColor);
}

.product-card .product-card-description p {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140.62%;
  color: #505050;
  margin-bottom: 12px;
}
.product-card .product-card-description div {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140.62%;
  color: #505050;
  margin-bottom: 12px;
}

.product-card .product-card-description p b {
  cursor: pointer;
}

.product-card-description-details > :last-child {
  margin-bottom: 80px !important;
}

.product-card-description-details > :last-child > :last-child {
  display: grid;
  align-items: start;
}
.product-card-description-details > :last-child > :last-child .product-card-description-details-item-prc {
  width: 100%;
  margin-top: 10px;
}

.product-card-description-details > :last-child > :last-child .product-card-description-details-item-prc > ul {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 0px 15px;
  margin-left: 0px;
  padding: 0px 14px;
}
.product-card-description-details-item {
  display: block;
}

.product-card-description-details-item.product-card-location {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.product-card-description-details-item p {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-card-description-details-item div {
  display: grid;
  grid-template-columns: auto;
  align-items: center;
}

.product-card-description-details-item.product-card-location p {
  justify-content: flex-start;
  align-items: center;
}

.product-card-description-details-item.product-card-location p:last-child {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: 900 !important;
}

.product-card-description-details-item p img {
  width: 16px;
}

.product-card-description-details-item p span {
  margin-left: 5px;
  font-weight: 500;
}


.product-card-description-details-item div span {
  margin-left: 5px;
  font-weight: 500;
}

.product-card-description-details-item p small {
  font-size: 10px;
  margin-top: 5px;
  color: #1f1f1f;
}

.product-card .product-button {
  position: absolute;
  bottom: 20px;
  left: 0px;
  right: 0px;
  text-align: center;
}

.product-card .product-button button {
  padding-right: 40px;
  padding-left: 40px;
  font-size: 14px;
}

.product-card .product-button button:hover {
  background: transparent;
  border: 1px solid var(--mainYellowColor);
  font-size: 13px;
}

@media screen and (max-width: 1150px) {
  .about-banner {
    padding: 80px 100px;
  }

  .about-banner h4 {
    font-size: 40px;
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 700px) {
  .hideToSmall,
  .product-card hr {
    display: none !important;
  }
}

@media screen and (max-width: 540px) {
  .product-card {
    border-radius: 10px;
  }

  .product-card .product-card-img .product-card-category {
    padding: 4px 8px;
    font-size: 10px;
  }
  .product-card .product-card-img img {
    width: 100%;
    height: 80px;
    border-radius: 10px;
  }

  .product-card .product-card-description {
    padding: 14px 10px 20px;
  }

  .product-card .product-card-description h3 {
    font-size: 12px;
    line-height: 14px;
  }

  .product-card-description-details-item p span {
    font-size: 11px;
  }

  .product-card .product-card-description .product-card-description-header h3 {
    margin-bottom: 20px;
  }

  .product-card .product-card-description .product-card-description-header p,
  .product-card .product-card-description .countryName {
    display: none;
  }

  .product-card-description-details-item-prc img {
    box-shadow: 0px 6px 8px 8px rgba(189, 189, 189, 0.22);
  }

  .product-card .product-button button {
    padding-right: 20px;
    padding-left: 20px;
    font-size: 11px;
    width: 100%;
  }
}
</style>
