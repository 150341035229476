<template>
  <div>
    <LoginModal />
    <AlertPopup />
    <VerifyPinCodeModal />
    <PageLoading />
    <InvestmentRequestModal />
  </div>
</template>

<script>
import VerifyPinCodeModal from "./VerifyPinCodeModal.vue";
import LoginModal from "./LoginModal.vue";
import AlertPopup from "./AlertPopup.vue";
import PageLoading from "../loading/PageLoading.vue";
import InvestmentRequestModal from "./InvestmentRequestModal.vue";
export default {
  components: {
    LoginModal,
    VerifyPinCodeModal,
    AlertPopup,
    PageLoading,
    InvestmentRequestModal
  },
};
</script>

<style></style>
