import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Localbase from "localbase";
import axios from "axios";
import VueAxios from "vue-axios";
import GAuth from "vue3-google-oauth2";
const gAuthOptions = {
  clientId:
    "231300972707-b32s9k2iidsra2juts7onkkikjtr4nko.apps.googleusercontent.com",
  scope: "email",
  prompt: "consent",
  fetch_basic_profile: false,
};
import myMixin from "./mixins/";
let db = new Localbase("db");
db.config.debug = false;

const app = createApp(App);
app.config.globalProperties.axios = axios;
app.config.globalProperties.$localBase = db;
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

const token = localStorage.getItem("Appoen");
if (token) {
  axios.defaults.headers.common["Authorization"] = token;
} else {
  localStorage.clear();
}

app.use(store);
app.use(router);
app.use(VueAxios, axios);
app.use(GAuth, gAuthOptions);
app.mixin(myMixin);

app.mount("#app");
