<template>
  <div
    class="products_categories"
  >
    <div
      class="products_categories_item"
      :class="selectedCategory === 'Relsify_Realty' && 'active'"
      @click="filterProduct('Relsify_Realty')"
    >
      <img src="@/assets/img/vectors/relsify-realty.svg" alt="" />
      <p>Relsify Realty</p>
    </div>
    <div
      class="products_categories_item"
      :class="selectedCategory === 'Shortletify' && 'active'"
      @click="filterProduct('Shortletify')"
    >
      <img src="@/assets/img/vectors/shortletify.svg" alt="" />
      <p>Shortletify</p>
    </div>
    <div
      class="products_categories_item"
      :class="selectedCategory === 'Vestify' && 'active'"
      @click="filterProduct('Vestify')"
    >
      <img src="@/assets/img/vectors/vestify.svg" alt="" />
      <p>Vestify</p>
    </div>
    <div
      class="products_categories_item"
      :class="selectedCategory === 'PropShare' && 'active'"
      @click="filterProduct('PropShare')"
    >
      <img src="@/assets/img/vectors/propshare.svg" alt="" />
      <p>PropShare</p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { productData } from "../../../utils/productData";

export default {
  computed: {
    ...mapGetters(["projectCategory"]),
    selectedCategory: {
      get() {
        return this.$store.state.selectedCategory;
      },
      set(value) {
        return (this.$store.state.selectedCategory = value);
      },
    },
    projects: {
      get() {
        return this.$store.state.projects
      },
      set() {
        if (this.$store.state.selectedCategory) {
          this.$store.state.projects = productData[this.selectedCategory]
        }
      }
    },
    searchText: {
      get() {
        return this.$store.state.searchText;
      },
      set(value) {
        return (this.$store.state.searchText = value);
      },
    }
  },

  methods: {
    ...mapActions([
      "setCurrentProject",
      "filterProjects",
      "searchProject",
      "setPageLoading",
    ]),

    filterProduct: function (category) {
      if (this.selectedCategory == category) {
        return;
      }
      this.selectedCategory = category;
    },
  }
};
</script>

<style scoped>
.products_categories {
  width: 700px;
  margin: 50px auto 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.products_categories_item {
  text-align: center;
  margin: 0px 18px;
  cursor: pointer;
}

.products_categories_item:first-child {
  margin-left: 0px;
}

.products_categories_item:last-child {
  margin-right: 0px;
}

.products_categories_item img {
  width: 73px;
  background: #ffffff;
  border: 1px solid var(--mainGreenColor);
  border-radius: 18px;
  padding: 2px 6px;
  transition: all 300ms ease;
}

.products_categories_item img:hover {
  background: var(--mainLightGreenColor);
}

.products_categories_item.active img {
  background: white;
  border-width: 6px;
}

.products_categories_item p {
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-top: 10px;
  margin-bottom: 0px;
  color: var(--mainGreenColor);
  font-size: 14px;
}

@media screen and (max-width: 1250px) {
  .products_categories {
    width: 680px;
    margin: 50px auto 30px;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 750px) {
  .products_categories {
    width: 100%;
    margin: 50px auto 30px;
  }

  .products_categories_item img {
    width: 60px;
    /* padding: 12px 16px; */
  }

  .products_categories_item p {
    margin-top: 10px;
    font-size: 13px;
  }
}

@media screen and (max-width: 570px) {
  .products_categories {
    width: 100%;
    margin: 50px auto 30px;
    display: flex !important;
    flex-direction: row;
    justify-content: flex-start;
    overflow-x: scroll;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
  }

  .products_categories::-webkit-scrollbar {
    width: 0px;
  }

  .products_categories_item {
    width: 100px;
    margin: 0px;
  }
  .products_categories_item img {
    width: 48px;
    padding: 0px;
  }
  .products_categories_item p {
    white-space: nowrap;
  }
}

@media screen and (max-width: 480px) {
  .products_categories_item {
    width: 90px;
  }
  .products_categories_item img {
    width: 45px;
    padding: 0px;
    border-radius: 10px;
  }

  .products_categories_item p {
    font-size: 12px;
  }
}
</style>
