import axios from "axios";
import Localbase from "localbase";
let db = new Localbase("db");
db.config.debug = false;
export const setSideNotificationModal = ({ commit }, status) => {
  commit("SET_SIDENOTIFICATION_MODAL", status);
};

// getUser login details
export const saveLoginDetails = ({ commit }, payload) => {
  let saveToDb = db.collection("user").doc("login-info").set(payload);
  if (saveToDb) {
    commit("SAVE_USER_LOGIN_INFO", payload);
  }
};

// getUser login details
export const getLoginDetails = async ({ commit }) => {
  let loginInfo = await db.collection("user").doc("login-info").get();
  // console.log(loginInfo);
  if (loginInfo) {
    commit("SAVE_USER_LOGIN_INFO", loginInfo);
  }
};

export const saveGoogleDetailsToDB = async ({ state, dispatch }, payload) => {
  let url = `${state.RELSIFY_URL}/api/verify-google`;
  var payloadData = {
    idToken: payload.id_token,
  };

  const result = await axios.post(url, payloadData);

  try {
    console.log(result.data);
    if (!result.data.error) {
      // commit("SET_ACTIVITY_LOGS", result.data.data);
      console.log(result.data);
      localStorage.setItem("Appoen", result.data.meta.token);
      localStorage.setItem("actForms", result.data.data._id);

      axios.defaults.headers.common["Authorization"] = result.data.meta.token;
      setTimeout(() => {
        dispatch("globalFunction");
        dispatch("saveLoginDetails", result.data.data);
        // dispatch("setPageLoading", false);

        location.reload();
      }, 3300);
    }
  } catch (error) {
    error;
    // console.log(error);
  }
  return result;
};

export const logoutUserFromAccount = ({ commit, state }) => {
  return new Promise((resolve) => {
    commit("LOGOUT");
    let dashboardUrl = state.DASHBOARD_URL;
    var createGuest = require("cross-domain-storage/guest");
    var bazStorage = createGuest(dashboardUrl);

    bazStorage.remove("Appoen", function (error, data) {
      // value for the key of 'fizz' will be retrieved from localStorage on www.baz.com
      error, data;
    });
    bazStorage.remove("actForms", function (error, data) {
      // value for the key of 'fizz' will be retrieved from localStorage on www.baz.com
      error, data;
    });

    db.collection("user").doc("user-info").delete();
    db.collection("user").doc("login-info").delete();
    localStorage.clear();
    localStorage.clear();
    setTimeout(() => {
      location.reload();
    }, 3000);

    delete axios.defaults.headers.common["Authorization"];
    resolve();
  });
};

export const setMobileMenu = ({ commit }) => {
  commit("SET_MOBILE_MENU");
};

export const closeMobileMenu = ({ commit }) => {
  commit("CLOSE_MOBILE_MENU");
};

export const setPageLoading = ({ commit }, status) => {
  commit("SET_PAGE_LOADING", status);
};

// set vetrify pin code modal
export const setVerifyPinCodeModal = ({ commit }, payload) => {
  commit("SET_VERIFY_PIN_CODE_MODAL", payload);
};

// Verify pincode modal
export const verifyPinCodeModal = (state) => {
  return state.verifyPinCodeModal;
};

// Verify Alert message modal
export const alertMessageModal = (state) => {
  return state.alertMessageModal;
};

// set alert message modal
export const setAlertMessageModal = ({ commit }, payload) => {
  commit("SET_ALERT_MESSAGE_MODAL", payload);
};

// set alert popUp
export const setAlertpopUp = ({ commit }, payload) => {
  commit("SET_ALERT_POPUP", payload);
};

// set modal loading
export const setModalLoading = ({ commit }, status) => {
  commit("SET_MODAL_LOADING", status);
};

// set modal loading
export const setLoginModal = ({ commit }, payload) => {
  commit("SET_LOGIN_MODAL", payload);
};

export const setInvestmentRequestModal = ({ commit }, payload) => {
  commit("SET_INVESTMENT_REQUEST_MODAL", payload);
};

// update current page name
export const setCurrentPageName = ({ commit }, data) => {
  commit("SET_CURRENT_PAGE_NAME", data);
};

// set login
export const globalFunction = ({ dispatch, commit }) => {
  const token = !!localStorage.getItem("Appoen");
  if (token) {
    dispatch("getLoginDetails");
    dispatch("getUserProfile");

    // var createGuest = require("cross-domain-storage/guest");
    // var bazStorage = createGuest("http://localhost:8084/");
    // console.log(bazStorage);
    // bazStorage.get("Appoen", function (error, data) {
    //   // value for the key of 'fizz' will be retrieved from localStorage on www.baz.com
    //   if (error) {
    //     console.log(error);
    //   } else {
    //     console.log(data);
    //   }
    // });
  }
  // dispatch("getProjects", {
  //   page: 1,
  //   limit: 30,
  // });
  dispatch("getFAQS");

  commit("GET_COUNTRIES");
  commit("GET_STATES");
};

export const login = ({ commit }, data) => {
  // console.log(data);
  commit("SAVE_USER_PROFILE", data);
};

// getUserProfile
export const getUserProfile = ({ commit, dispatch, state }) => {
  let url = `${state.RELSIFY_URL}/api/profile`;

  // console.log(url);
  axios
    .get(url)
    .then((data) => {
      // console.log(data);
      if (!data.data.error) {
        // console.log(data.data);

        let saveToDb = db
          .collection("user")
          .doc("user-info")
          .set(data.data.data);
        if (saveToDb) {
          commit("SAVE_USER_PROFILE", data.data.data);
        }
      } else {
        localStorage.clear();
        // location.reload();
      }
    })
    .catch((error) => {
      error;
      dispatch("logoutUserFromAccount");
    });
};

export const getProjects = async ({ commit, state }, payload) => {
  let url = `${state.RELSIFY_URL}/api/projects?page=${payload.page}&limit=${payload.limit}`;

  const response = await axios.get(url);

  try {
    if (!response.data.error) {
      // console.log(response.data);

      commit("SAVE_PROJECTS", response.data.data);
      state.projectCurrentPage = response.data.meta.page;
      state.projectTotalPage = response.data.meta.pages;

      // console.log(state.projectCurrentPage);
      // console.log(state.projectTotalPage);
    }
  } catch (error) {
    error;
  }

  return response;
};

export const filterProjects = async ({ commit, state }, url) => {
  const response = await axios.get(url);

  try {
    if (!response.data.error) {
      console.log(response.data);

      commit("SAVE_PROJECTS", response.data.data);
      state.projectCurrentPage = response.data.meta.page;
      state.projectTotalPage = response.data.meta.pages;
    }
  } catch (error) {
    error;
  }

  return response;
};

export const searchProject = async ({ commit, state }, payload) => {
  let url = `${state.RELSIFY_URL}/api/search?page=1&limit=200`;
  const response = await axios.post(url, payload);

  try {
    if (!response.data.error) {
      console.log(response.data);

      commit("SAVE_PROJECTS", response.data.data);
      // state.projectCurrentPage = response.data.meta.page;
      // state.projectTotalPage = response.data.meta.pages;
    }
  } catch (error) {
    error;
  }

  return response;
};

// get a Project
export const getSingleProject = ({ commit, state }, id) => {
  let url = `${state.RELSIFY_URL}/api/project/${id}/details`;
  // console.log(id);
  axios
    .get(url)
    .then((data) => {
      if (!data.data.error) {
        console.log(data.data);
        if (data.data.data) {
          let project = data.data.data;
          state.projectToBuy = project;
          project.selectedToken = project.tradeInfo.allowedCoins[0];
          if (project.tradeInfo.allowedCoins.length > 1) {
            project.selectedSellingToken = project.tradeInfo.allowedCoins[1];
          } else {
            project.selectedSellingToken = project.tradeInfo.allowedCoins[0];
          }

          commit("SET_CURRENT_PROJECT", project);
        }
      }
    })
    .catch((error) => console.log(error));
};

export const fetchMoreProjects = async ({ state }, currentPage) => {
  let url;
  if (state.selectedCategory == "") {
    url = `${state.RELSIFY_URL}/api/projects?page=${currentPage}&limit=20`;
  } else {
    url = `${state.RELSIFY_URL}/api/projects?page=${currentPage}&limit=20&category=${state.selectedCategory}`;
  }

  const result = await axios.get(url);
  return result;
};

export const saveMoreProjects = ({ commit }, data) => {
  // console.log(data);
  commit("SAVE_MORE_PROJECTS", data);
};

export const getFAQS = async ({ commit, state }) => {
  let url = `${state.RELSIFY_URL}/api/faqs`;

  const response = await axios.get(url);

  try {
    if (!response.data.error) {
      // console.log(response.data);

      commit("SAVE_FAQS", response.data.data);
    }
  } catch (error) {
    error;
  }

  return response;
};
