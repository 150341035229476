<template>
  <div class="all_products">
    <div class="product_home_desc" v-if="currentPageName == 'Home'">
      <h3 ref="productHeaderText" class="productHeaderText">
        <span class="text-yellow">High-Yielding</span> Assets Hand Picked For
        You
      </h3>
      <p ref="productDesc" class="productDesc">
        Explore marketplace, search well-projected properties, make a choice,
        co-own, and begin to earn dividends monthly, quarterly, or yearly.
      </p>
    </div>

    <!-- <p v-if="currentPageName == 'Home'">
      Explore marketplace, search well-projected properties, make a choice,
      co-own, and begin to earn dividends.
    </p> -->
    <div class="products">
      <ProductSearch v-if="currentPageName == 'ExploreMarketPlace'" />
      <ProductCategory />
    </div>
    <ProductSlide
      v-if="allProjects && allProjects.length > 0"
      :projects="allProjects"
    />
    <!-- <ProductSlide v-if="projects" :projects="projects" />
    <ProductSlide v-if="projects" :projects="projects" /> -->
    <div
      class="product_more_button text-center"
      v-if="currentPageName == 'Home'"
    >
      <button ref="productButton"  class="productButton" @click="$router.push({ name: 'ExploreMarketPlace' })">
        <span>Explore Marketplace </span>
        <i class="bx bxs-chevron-right"></i>
      </button>
    </div>
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="projects == null"
    >
      <div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <LoadingComponent />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProductSlide from "./ProductSlide.vue";
import ProductSearch from "./ProductSearch.vue";
import ProductCategory from "./ProductCategory.vue";
import LoadingComponent from "../../loading/LoadingComponent.vue";
import { productData } from "../../../utils/productData";
import { ref } from "vue";
export default {
  components: {
    ProductSlide,
    ProductSearch,
    ProductCategory,
    LoadingComponent,
  },
  computed: {
    ...mapGetters(["projects"]),
    allProjects: {
      get() {
        return this.$store.state.projects
      },
      set(value) {
        return (this.$store.state.projects = value)
      }
    },
    selectedCategory: {
      get() {
        return this.$store.state.selectedCategory
      },
      set(value) {
        return (this.$store.state.selectedCategory = value)
      },
    },
  },
  setup() {
    const productHeaderText = ref(null)
    const productDesc = ref(null)
    const productButton = ref(null)

    return {
      productHeaderText,
      productDesc,
      productButton
    }
  },
  // data() {
  //   return {
  //     // searchText: "",
  //   };
  // },
  watch: {
    selectedCategory() {
      this.allProjects = productData[this.selectedCategory]
    }
  },
  methods: {
    isScrolledIntoView(el) {
      const rect = el.getBoundingClientRect()
      const elemTop = rect.top
      const elemBottom = rect.bottom
      const isVisible = elemTop < window.innerHeight && elemBottom >= 0
      return isVisible
    },
    handleScroll() {
      /* if scrolled into view */
      if (this.productDesc) {
        if(this.isScrolledIntoView(this.productDesc)) {
          this.productDesc.classList.add('slide-in-left')
        } else {
          this.productDesc.classList.remove('slide-in-left')
        }
      }
      if (this.productHeaderText) {
        if (this.isScrolledIntoView(this.productHeaderText)) {
          this.productHeaderText.classList.add('slide-in-right')
        } else {
          this.productHeaderText.classList.remove('slide-in-right')
        }
      }
      if (this.productButton) {
        if (this.isScrolledIntoView(this.productButton)) {
          this.productButton.classList.add('slide-in-up')
        } else {
          this.productButton.classList.remove('slide-in-up')
        }
      }
    }
  },
  mounted() {
    this.selectedCategory = 'Relsify_Realty'
    setTimeout(() => {
      if (this.projects && this.currentPageName == "Home") {
        this.allProjects = productData[this.selectedCategory].slice(0, 4)
      } else {
        this.allProjects = productData[this.selectedCategory]
      }
      
    }, 2000)
  }
};
</script>

<style scoped>
.all_products {
  padding-bottom: 150px;
  overflow: hidden;
  transition: all .7s;
}

.all_products .product_home_desc {
  margin-top: 50px;
  margin-left: 90px;
}

.all_products .product_home_desc h3 {
  width: 490px;
  font-style: normal;
  font-weight: 600;
  font-size: 43px;
  line-height: 81px;
  color: #181818;
}

.all_products .product_home_desc p {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 31px;
  color: #181818;
}

.products {
  padding: 80px;
  background: #fff;
}

.product_more_button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product_more_button button {
  background: transparent;
  border: 2px solid #ffae1b;
  border-radius: 5px;
  padding: 10px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  font-size: 14px;
  transition: all 350ms ease-in-out;
}

.product_more_button button i {
  font-size: 16px;
}

.product_more_button button:hover {
  background: #ffae1b;
}

@media screen and (max-width: 1150px) {
  .all_products .product_home_desc {
    margin-left: 60px;
  }
}
@media screen and (max-width: 980px) {
  .all_products .product_home_desc {
    margin-bottom: -40px;
  }

  .all_products .product_home_desc h3 {
    font-size: 33px;
    line-height: 51px;
  }
}

@media screen and (max-width: 950px) {
  .all_products .product_home_desc {
    margin-top: 30px;
    margin-left: 36px;
  }

  .all_products .product_home_desc h3 {
    font-size: 33px;
    line-height: 50px;
    width: 90%;
    /* text-align: center; */
    /* margin-left: -20px; */
  }
  .all_products p {
    margin-right: 20px;
  }
}

@media screen and (max-width: 780px) {
  .all_products .product_home_desc {
    margin-top: 30px;
    margin-left: 28px;
  }
}
@media screen and (max-width: 700px) {
  .products {
    padding: 50px 40px;
  }

  .all_products .product_home_desc h3 {
    font-size: 30px;
    margin-left: 0px;
    /* line-height: 20px; */
  }
}

@media screen and (max-width: 570px) {
  .products {
    padding: 30px 0px 0px;
  }

  .all_products .product_home_desc h3 {
    font-size: 27px;
    width: 70%;
  }
  .all_products .product_home_desc p {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 480px) {
  .all_products {
    padding-bottom: 50px;
  }

  .products {
    padding: 10px 0px 0px;
  }
}

@media screen and (max-width: 380px) {
  .all_products .product_home_desc {
    margin-left: 0px;
    padding: 0px 18px;
  }

  .all_products .product_home_desc h3 {
    font-size: 23px;
    width: 78%;
    text-align: center;
    line-height: 38px;
    margin: 30px auto 20px;
  }
  .all_products .product_home_desc p {
    font-size: 16px;
    text-align: center;
    margin-right: 0px;
  }
}
</style>
