<template>
  <div>
    <HomeBanner />
    <ReachWithin />
    <ExploreDecideInvest />
    <ProductContainer />
  </div>
</template>

<script>
import HomeBanner from "../components/extras/banners/HomeBanner.vue";
import ExploreDecideInvest from "../components/extras/ExploreDecideInvest.vue";
import ProductContainer from "../components/extras/products/ProductContainer.vue";
import ReachWithin from "../components/extras/ReachWithin.vue";
import { mapActions } from "vuex";
export default {
  components: {
    HomeBanner,
    ExploreDecideInvest,
    ProductContainer,
    ReachWithin,
  },

  data() {
    return {
      title1: "Relsify Privacy Policy",
      title2: "Global Privacy Policy",
    };
  },

  methods: {
    ...mapActions(["setCurrentPageName"]),
  },

  mounted() {
    this.$store.state.searchText = "";
    this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
  },
};
</script>
