<template>
  <div class="alert-modal" v-if="loginModal.status">
    <div class="alert-modal-overlay" @click="closeLoginModal"></div>
    <div class="alert-modal-card vivify popInBottom">
      <div class="close-alert-button">
        <i class="bx bx-x" id="closeAlertModal" @click="closeLoginModal"></i>
      </div>

      <div class="alert-modal-body">
        <h4>Login</h4>
        <br />
        <form class="forms_div" autocomplete="off" v-on:submit.prevent="login">
          <div class="form_groups_grid">
            <div
              class="forms_div_tabs active"
              id="registerWithEmail"
              :class="registrationType"
            >
              <div class="form_groups_grid_item">
                <i class="bx bxs-user"></i>
                <input
                  type="text"
                  v-model="email"
                  @blur="validateForm"
                  @input="validateForm"
                  autocomplete="off"
                  placeholder="Email or phone number"
                />
              </div>
            </div>
            <div
              class="forms_div_tabs"
              id="registerWithPhone"
              :class="registrationType"
            >
              <label
                for=""
                style="
                  text-align: left !important;
                  width: 100%;
                  font-size: 12.5px;
                  margin-top: 10px;
                "
                >Phone Number must start with +(CountryCode)</label
              >
              <div class="form_grid_double">
                <div
                  class="form_groups_grid_item mb-3 mt-3 phone_register"
                  v-if="countries && countries.length"
                >
                  <img :src="countryFlag" v-if="countryFlag" alt="" />
                  <!-- <span>{{ country }}</span> -->
                  <select v-model="country" @change="setCountry">
                    <option value="" disabled>Select Country code</option>
                    <option
                      :value="country.isoCode"
                      v-for="(country, index) in countries"
                      :key="index"
                    >
                      {{ country.name }}
                    </option>
                  </select>
                </div>

                <div class="form_groups_grid_item">
                  <span
                    ><b>{{ countryDialCode }}</b></span
                  >
                  <input
                    type="text"
                    v-model="phoneNumber"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                    @input="validateForm"
                    autocomplete="off"
                    placeholder="phone number"
                  />
                </div>
              </div>
              <!-- <div class="form_groups_grid_item">
          <span><b></b></span>
          <input
            type="text"
            v-model="phoneNumber"
            @blur="validateForm"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
            @input="validateForm"
            autocomplete="off"
            placeholder="phone number e.g +2348060000000"
          />
        </div> -->
            </div>

            <div class="form_groups_grid_item">
              <i class="bx bxs-lock"></i>
              <input
                :type="showPassword ? 'text' : 'password'"
                @input="validateForm"
                autocomplete="off"
                @blur="validateForm"
                placeholder="Password"
                v-model="password"
              />
              <i
                class="bx bx-show-alt"
                v-if="!showPassword"
                style="cursor: pointer"
                @click="togglePasword"
              ></i>
              <i
                class="bx bx-hide"
                v-if="showPassword"
                style="cursor: pointer"
                @click="togglePasword"
              ></i>
            </div>

            <button type="submit" :disabled="emptyFields">
              <span v-if="!loading">LOGIN </span>
              <span class="loader" v-if="loading"></span>
            </button>

            <br />
          </div>
        </form>
        <SocialLoginRegistration />
      </div>
    </div>
  </div>
</template>

<script>
import "@/mixins";
import "@/assets/css/modal.css";
import { mapGetters, mapActions } from "vuex";
import SocialLoginRegistration from "../extras/SocialLoginRegistration.vue";
export default {
  components: {
    SocialLoginRegistration,
  },
  computed: {
    ...mapGetters(["loginModal"]),

    registrationType: {
      get() {
        return this.$store.state.registrationType;
      },
      set(value) {
        return (this.$store.state.registrationType = value);
      },
    },

    countries() {
      return this.$store.state.countries;
    },
    countryFlag: {
      get() {
        return this.$store.state.countryFlag;
      },
      set(value) {
        return (this.$store.state.countryFlag = value);
      },
    },

    countryDialCode: {
      get() {
        return this.$store.state.countryDialCode;
      },
      set(value) {
        return (this.$store.state.countryDialCode = value);
      },
    },
  },

  data() {
    return {
      email: "",
      phoneNumber: "",
      password: "",
      showPassword: false,
      loading: false,
      emptyFields: true,
      responseData: null,
      country: "NG",
    };
  },

  methods: {
    ...mapActions(["setLoginModal"]),
    closeLoginModal: function () {
      this.setLoginModal({
        status: false,
        payload: null,
      });
    },

    togglePasword: function () {
      this.showPassword = !this.showPassword;
      this.validateForm();
    },

    setCountry: function () {
      this.$store.commit("SET_COUNTRY_FLAG", this.country);
      this.phoneNumber = "";
      this.validateForm();
    },

    validateForm: function () {
      if (this.registrationType == "withEmail") {
        if (!this.email || !this.password) {
          this.emptyFields = true;

          return false;
        }

        this.emptyFields = false;
        return true;
      }
      if (this.registrationType == "withPhone") {
        if (!this.phoneNumber || !this.password) {
          this.emptyFields = true;

          return false;
        }

        this.emptyFields = false;
        return true;
      }
    },

    login: function () {
      this.loading = true;
      this.emptyFields = true;

      let url = `${this.RELSIFY_URL}/api/login`;
      let payload;

      if (this.registrationType == "withEmail") {
        payload = {
          email: this.email,
          password: this.password,
        };
      }

      if (this.registrationType == "withPhone") {
        payload = {
          phoneNumber: this.phoneNumber,
          password: this.password,
        };
      }

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      };
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          this.validateForm();
          this.loading = false;
          if (!data.error) {

            let newData = {
              secretTokenId: data.data.secretTokenId,
              email: this.email,
              phoneNumber: this.phoneNumber,
            };

            this.responseData = data.data;
            this.setVerifyPinCodeModal({
              status: true,
              title: "Confirm Email Token",
              desc: data.meta.message,
              redirectUrl: null,
              type: "LOGIN",
              payload: newData,
            });
          } else {
            this.setAlertpopUp({
              status: true,
              title: "Login Status",
              des: data.meta.message,
              type: "error",
              payload: null,
            });
          }
        })
        .catch((error) => console.error('ERROR', error));

      // response.json();
      // try {
      //   this.validateForm();
      //   this.loading = false;
      //   if (!data.error) {
      //     console.log(data);
      //   } else {
      //     this.setAlertpopUp({
      //       status: true,
      //       title: "Login Error",
      //       des: data.meta.message,
      //       type: "error",
      //       payload: null,
      //     });
      //     console.log(data);
      //   }
      // } catch (error) {
      //   console.log(error);
      // }

      // console.log(data);
    },
  },
  mounted() {
    this.inputCodeNext();
  },
};
</script>

<style scoped>
[class*="loader"]:before {
  content: "Please wait...";
}
</style>
