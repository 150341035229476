export const productData = {
    Relsify_Realty: [
        {
            product: '',
            description: 'Beautiful 5 Bedroom Fully Detached Duplex with BQ at Orchid Lekki Lagos Nigeria for 120 Million Naira.',
            mainImage: {
                url: 'relsify_realty_1'
            },
            features: [
                '1 living room',
                'Family Lounge',
                'All rooms En-Suite',
                'CCTV Cameras',
                'Central Sound System',
                'POP finishes',
                'Interlocked concrete Floor compound',
                'Security House',
                'Fully Fitted Kitchen ( microwave, oven, Heat  Extractor)',
                'water heater',
                'very spacious compound'
            ],
            type: '5 Bedrooms',
            location: 'Orchid, Lekki',
            price: '₦120M (Outright) ',
            caution: '₦50k (refundable)',
            CTA: 'BUY NOW'
        },
        {
            product: '',
            description: '4 BEDROOM FULLY DETACHED DUPLEX House.',
            mainImage: {
                url: 'relsify_realty_2'
            },
            features: [
                'All Rooms Ensuite',
                'Stamped Concrete Floor',
                'Marble Stairs',
                'POP Ceilings',
                'Fully Fitted Kitchen',
                'Spacious Living Area',
                'Balcony',
                'Secured Estate',
                'Water Heaters',
                'Walk-in Showers',
                'Serene Environment',
                'Bath Tub'
            ],
            type: '4 Bedrooms',
            location: 'Ajah, Lekki',
            price: '₦120M (Outright)',
            caution: '₦50k (refundable)',
            CTA: 'BUY NOW'
        },
        {
            product: '',
            description: 'EXQUISITELY FINISHED 5 BED FULLY DETACHED MODERN HOME.',
            mainImage: {
                url: 'relsify_realty_3'
            },
            features: [
                'Luxury Modern Appurtenances',
                'Detailed Tasteful Finishing',
                'Secure Estate',
                'Ample Car Park',
                'Mind Blowing Lightings',
                'Astonishing Woodwork',
                'Stamp Concrete Floor',
                'Hi-Tech Car Port',
                'Swimming Pool',
                'Fully Equipped Kitchen',
                'Audio Music System',
                'Green Area',
                'CCTC Surveillance',
                'Boy’s Quarters',
                'Great Neighbourhood'
            ],
            type: '4 Bedrooms',
            location: 'Lekki County, Lekki',
            price: '₦230m (Outright) ',
            caution: '₦50k (refundable)',
            CTA: 'BUY NOW'
        },
        {
            product: '',
            description: 'DISTINCTIVELY CARVED 5 BED FULLY DETACHED DUPLEX.',
            mainImage: {
                url: 'relsify_realty_4'
            },
            features: [
                'Unique Architectural Masterpiece',
                'Decently Finished To Detail',
                'Swimming Pool',
                'Access Controlled Secure Estate',
                'Integrated Audio System',
                'Astonishing Woodworks',
                'Stamp Concrete Floor',
                'Hi-Tech Car Port',
                'Green Area',
                'CCTV Surveillance',
                'Family Lounge',
                'Beautifully Fitted Wardrobes',
                'Fully Fitted Kitchen',
                'Gorgeous Lightings',
                'Modern Appurtenances',
                'Luxury Sanitary Wares',
                'Great Neighbourhood' 
            ],
            type: '5 Bedrooms',
            location: 'Ajah, Lekki',
            price: '₦135m (Outright) ',
            caution: '₦100k (refundable) ',
            CTA: 'BUY NOW'
        },
    ],
    Shortletify: [
        {
            product: '',
            description: '1 Bedroom shortlet apartment at Buniameen oyekan close, Lekki, Close to petrocam filling station.',
            mainImage: {
                url: 'shortlet_1'
            },
            features: [
                'Gated and Secured Estate', 
                '24/7 Power Supply',
                'Serene environment',
                'Very fast internet',
                'Ps4 Pro',
                'Netflix / YouTube' ,
                'En-suite room',
                'Daily housekeeping',
                'Dedicated car park space',
                'Equipped kitchen',
                'Washing machine' 
            ],
            type: '1 Bedroom',
            location: 'Buniameen oyekan close',
            price: '₦55k per night',
            caution: '₦50k (refundable)',
            CTA: 'BOOK NOW'
        },
        {
            product: '',
            description: "Book this luxurious 2-bedroom apartment @ Sapphire blue waters residence Oniru Vi.",
            mainImage: {
                url: 'shortlet_2'
            },
            features: [
                'Serene environment',
                'Very fast internet',
                'Netflix / YouTube',
                'En-suite room',
                'Daily housekeeping',
                'Equipped kitchen', 
                'Ocean View',
                'Gym',
                'Swimming pool',
                'Snooker',
                'Tennis board',
                'Lots - More',
                'Balcony',
                'Exclusive finishing',
                'Basement car parking',
                '24hrs Electricity',
                'Maximum security ',
                '14th floor',
            ],
            type: '2 Bedrooms',
            location: 'Oniru, VI',
            price: '₦110k per night',
            caution: '₦50k (refundable)',
            CTA: 'BOOK NOW'
        },
        {
            product: '',
            description: "3 bedroom Alexa smart home, Balconies in all three 3 rooms with a waterfront view.",
            mainImage: {
                url: 'shortlet_3'
            },
            features: [
                'Open plan kitchen',
                'Netflix', 
                'DSTV',
                'super fast internet',
                'ceiling Bluetooth speakers',
                'private jetty access'
            ],
            type: '3 Bedrooms',
            location: 'Lekki Phase 1',
            price: '₦160k per night',
            caution: '₦50k (refundable)',
            CTA: 'BOOK NOW'
        },
        {
            product: '',
            description: "Luxury 5 bedroom duplex apartment in Ikate.",
            mainImage: {
                url: 'shortlet_4'
            },
            features: [
                'Self compound',
                'Snooker',
                'PS5',
                'pool'
            ],
            type: '5 Bedrooms',
            location: 'Ikate, Lekki',
            price: '₦210k per night',
            caution: '₦100k (refundable)',
            CTA: 'BOOK NOW'
        },
    ],
    Vestify: [
        {
            product: '',
            description: 'Invest in a shortlet property plan with Relsify and get 70% ROI in 730days. Only 20 slots available.',
            mainImage: {
                url: 'vestify_1'
            },
            features: [
                'Gated and Secured Estate', 
                '24/7 Power Supply',
                'Serene environment',
                'Very fast internet',
                'Ps4 Pro',
                'Netflix / YouTube' ,
                'En-suite room',
                'Daily housekeeping',
                'Dedicated car park space',
                'Equipped kitchen',
                'Washing machine' 
            ],
            type: '2 Bedrooms',
            location: 'Lekki, Lagos',
            exit_period: 730,
            occupancy_rate: 70,
            target_raise: '₦30,000,000',
            min_entry: '₦1,500,000',
            slot: 20,
            ROI: 70,
            price: '₦150k per night',
            caution: '₦50k (refundable)',
            CTA: 'TAKE A SLOT'
        },
        {
            product: '',
            description: 'Invest in a shortlet property plan with Relsify and get 50% ROI in 730days. Only 10 slots available.',
            mainImage: {
                url: 'vestify_1'
            },
            features: [
                'Serene environment',
                'Very fast internet',
                'Netflix / YouTube',
                'En-suite room',
                'Daily housekeeping',
                'Equipped kitchen', 
                'Ocean View',
                'Gym',
                'Swimming pool',
                'Snooker',
                'Tennis board',
                'Lots - More',
                'Balcony',
                'Exclusive finishing',
                'Basement car parking',
                '24hrs Electricity',
                'Maximum security ',
                '14th floor',
            ],
            type: '1 Bedroom',
            location: 'Lekki, Lagos',
            exit_period: 730,
            occupancy_rate: 70,
            target_raise: '₦13,000,000',
            min_entry: '₦1,500,000',
            slot: 10,
            ROI: 50,
            price: '₦60k per night',
            caution: '₦50k (refundable)',
            CTA: 'TAKE A SLOT'
        },

    ],
    PropShare: [

    ]
}
