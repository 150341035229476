<template>
  <div class="reachWithin">
    <h4 ref="reach_within_header_text" :class="{
      'slide-in-right': is_reach_within_header_text_visible
    }"
    > Say <span class="text-green">HELLO</span> to our four pillars </h4>
    <div class="reachWithin_grid">
      <div ref="reach_within_card_1" class="reachWithin_grid_item" 
      :class="{
        'zoom-in':is_reach_within_card_1_visible
      }"
      style="overflow: hidden;"
      >
        <img
          src="@/assets/img/vectors/relsify-realty.svg"
          style="width: 52px; height: 52px; object-fit: cover"
          alt=""
        />
        <h5>Relsify Realty</h5>
        <p>
          Dedicated to traditional real estate demands, showcasing prime lands and luxurious homes, guided by our team of experts.
        </p>
      </div>
      <div ref="reach_within_card_2" class="reachWithin_grid_item" :class="{
        'zoom-in':is_reach_within_card_2_visible
      }">
        <img
          src="@/assets/img/vectors/shortletify.svg"
          style="width: 52px; height: 52px; object-fit: cover"
          alt=""
        />
        <h5>Shortletify</h5>
        <p>
          Your gateway to diverse short-let needs, providing flexible solutions for temporary stays.
        </p>
      </div>
      <div ref="reach_within_card_3" class="reachWithin_grid_item" :class="{
        'zoom-in':is_reach_within_card_3_visible
      }">
        <img
          src="@/assets/img/vectors/vestify.svg"
          style="width: 52px; height: 52px; object-fit: cover"
          alt=""
        />
        <h5>Vestify</h5>
        <p>
          Unlocking investment value for individuals through profitable co-investment and co-acquisition opportunities.
        </p>
      </div>
      <div ref="reach_within_card_4" class="reachWithin_grid_item" :class="{
        'zoom-in':is_reach_within_card_4_visible
      }">
        <img
          src="@/assets/img/vectors/propshare.svg"
          style="width: 52px; height: 52px; object-fit: cover"
          alt=""
        />
        <h5>PropShare</h5>
        <p>
          Upholding our original vision, democratizing access to lucrative real estate using blockchain technology.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  setup() {
    const reach_within_header_text = ref(null)
    const reach_within_header_count = ref(0)
    const is_reach_within_header_text_visible = ref(false)
    const reach_within_header_text_observer = new IntersectionObserver(([entry]) => {
      if (reach_within_header_count.value) {
        return
      }
      is_reach_within_header_text_visible.value  = entry.isIntersecting
      reach_within_header_count.value++
    })

    const reach_within_card_1 = ref(null)
    const reach_within_card_1_count = ref(0)
    const is_reach_within_card_1_visible = ref(false)
    const reach_within_card_1_observer = new IntersectionObserver(([entry]) => {
      if (reach_within_card_1_count.value) {
        return
      }
      is_reach_within_card_1_visible.value  = entry.isIntersecting
      reach_within_card_1_count.value
    })

    const reach_within_card_2 = ref(null)
    const reach_within_card_2_count = ref(0)
    const is_reach_within_card_2_visible = ref(false)
    const reach_within_card_2_observer = new IntersectionObserver(([entry]) => {
      if (reach_within_card_2_count.value) {
        return
      }
      is_reach_within_card_2_visible.value  = entry.isIntersecting
      reach_within_card_2_count.value
    })

    const reach_within_card_3 = ref(null)
    const reach_within_card_3_count = ref(0)
    const is_reach_within_card_3_visible = ref(false)
    const reach_within_card_3_observer = new IntersectionObserver(([entry]) => {
      if (reach_within_card_3_count.value) {
        return
      }
      is_reach_within_card_3_visible.value  = entry.isIntersecting
      reach_within_card_3_count.value
    })

    const reach_within_card_4 = ref(null)
    const reach_within_card_4_count = ref(0)
    const is_reach_within_card_4_visible = ref(false)
    const reach_within_card_4_observer = new IntersectionObserver(([entry]) => {
      if (reach_within_card_4_count.value) {
        return
      }
      is_reach_within_card_4_visible.value  = entry.isIntersecting
      reach_within_card_4_count.value
    })

    return {
      reach_within_header_text,
      is_reach_within_header_text_visible,
      reach_within_header_text_observer,

      reach_within_card_1,
      is_reach_within_card_1_visible,
      reach_within_card_1_observer,

      reach_within_card_2,
      is_reach_within_card_2_visible,
      reach_within_card_2_observer,

      reach_within_card_3,
      is_reach_within_card_3_visible,
      reach_within_card_3_observer,

      reach_within_card_4,
      is_reach_within_card_4_visible,
      reach_within_card_4_observer
    }
  },
  mounted() {
    this.reach_within_header_text_observer.observe(this.reach_within_header_text)
    this.reach_within_card_1_observer.observe(this.reach_within_card_1)
    this.reach_within_card_2_observer.observe(this.reach_within_card_2)
    this.reach_within_card_3_observer.observe(this.reach_within_card_3)
    this.reach_within_card_4_observer.observe(this.reach_within_card_4)
  }
};
</script>

<style scoped>
.reachWithin {
  background: #fff;
  width: 100%;
  padding: 90px 100px;
  overflow: hidden;
}

.reachWithin h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: #181818;
  margin-bottom: 90px;
}

.reachWithin_grid {
  width: 100%;
  margin: 60px auto 30px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px 30px;
  align-items: center;
}

.reachWithin_grid_item {
  background: rgba(24, 133, 111, 0.05);
  border-radius: 14.0415px;
  padding: 20px 18px;
}

.reachWithin_grid_item:nth-of-type(even) {
  background: var(--mainLightYellowColor) !important;
}

.reachWithin_grid_item h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 21px;
  color: #383838;
  margin-top: 19px;
  margin-bottom: 25px;
}

.reachWithin_grid_item p {
  font-style: normal;
  font-weight: normal;
  font-size: 14.9776px;
  line-height: 21px;
  color: #383838;
}

@media screen and (max-width: 1300px) {
  .reachWithin {
    width: 100%;
    padding: 90px 30px;
  }

  .reachWithin h4 {
    font-size: 39px;
  }

  .reachWithin_grid {
    /* width: 80%; */
    margin: 60px auto 50px;
    gap: 40px 20px;
  }

  .reachWithin_grid_item h5 {
    font-size: 20px;
  }
}

@media screen and (max-width: 1250px) {
  .reachWithin_grid_item h5 {
    font-size: 15px;
  }

  .reachWithin_grid_item p {
    font-size: 13px;
  }
}

@media screen and (max-width: 1098px) {
  .reachWithin_grid {
    width: 100%;
    margin: 60px auto 50px;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px 20px;
  }
}

@media screen and (max-width: 950px) {
  .reachWithin {
    padding: 90px 25px;
  }
}

@media screen and (max-width: 870px) {
  .reachWithin {
    padding: 90px 35px;
  }
  .reachWithin h4 {
    font-size: 35px;
  }

  .reachWithin_grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px 20px;
  }
}

@media screen and (max-width: 750px) {
  .reachWithin_grid {
    gap: 40px 15px;
  }
}

@media screen and (max-width: 600px) {
  .reachWithin h4 {
    font-size: 30px;
    text-align: center;
  }

  .reachWithin_grid {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    margin-top: 12px;
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 460px) {
  .reachWithin h4 {
    font-size: 24px;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 330px) {
  .reachWithin {
    padding: 60px 19px;
  }

  .reachWithin h4 {
    line-height: 38px;
  }
}
</style>
