<template>
  <div style="position: relative !important;">
    <Header />
    <AllModals />

    <div class="main-page">
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>

    <!-- <div class="menu_wrapper_div"></div> -->
    <Footer />
    <a href="https://wa.link/7nxraq" target="_blank" style="
      position: fixed;
      bottom: 5vh;
      right: 5vw;
      background-color: transparent;
      width: 80px;
      animation: bounce 10s infinite ease-in-out;
    ">
      <img src="./assets//img/vectors/whatsapp.png" alt="">
  </a>
  </div>
</template>

<script>
import "./assets/css/style.css";
import Header from "./components/nav/Header.vue";
import Footer from "./components/footer/Footer.vue";
import AllModals from "./components/modals/AllModals.vue";
import $ from "jquery";
import { mapActions, mapMutations } from "vuex";
import store from "./store";

// var createHost = require("cross-domain-storage/host");
// createHost([
//   {
//     origin: "http://localhost:8084/",
//     allowedMethods: ["get", "set", "remove"],
//   },
// ]);

export default {
  components: {
    Header,
    Footer,
    AllModals,
  },

  data() {
    return {
      hasToken: false,
    };
  },

  methods: {
    ...mapActions(["globalFunction"]),
    ...mapMutations(["GET_COUNTRIES"]),
    checkUserAuthentication: function () {
      // const token = !!localStorage.getItem("Appoen");

      // if (token) {
      this.globalFunction();
      // }
    },

    addFixNav: function () {
      $(window).scroll(function () {
        var sticky = $("#top_header"),
          scroll = $(window).scrollTop();

        if (scroll >= 100) sticky.addClass("header_fixed");
        else sticky.removeClass("header_fixed");
      });
    },

    checkIfUserIsAuthenticatedCrossSite: function () {
      var createGuest = require("cross-domain-storage/guest");

      var bazStorage = createGuest(store.state.DASHBOARD_URL);
      console.log(bazStorage);
      bazStorage.get("Appoen", function (error, value) {
        // value for the key of 'fizz' will be retrieved from localStorage on www.baz.com
        error;
        console.log(value);

        if (value) {
          localStorage.setItem("Appoen", value);
          this.$store.state.token = value;
          // this.hasToken = true;
          // location.reload();
        }
        if (value == null) {
          localStorage.removeItem("Appoen");
        }
      });

      bazStorage.get("actForms", function (error, value) {
        // value for the key of 'fizz' will be retrieved from localStorage on www.baz.com
        error;
        // console.log(value);
        if (value) {
          localStorage.setItem("actForms", value);
          // location.reload();
          // this.hasToken = true;
        }

        if (value == null) {
          localStorage.removeItem("actForms");
        }
      });

      // setTimeout(this.checkIfUserIsAuthenticatedCrossSite, 10000);

      // if (!this.hasToken) {
      //   setTimeout(this.checkIfUserIsAuthenticatedCrossSite, 5000);
      // }
    },
  },

  mounted() {
    this.addFixNav();
    this.checkUserAuthentication();
    this.GET_COUNTRIES();
    this.checkIfUserIsAuthenticatedCrossSite();

    $("body").click(() => {
      // $(".inner_message_action").hide();
      if (document.querySelector(".loggedin_user")) {
        if ($(".loggedin_user_dropdown").hasClass("showMenuDropDown")) {
          $(".loggedin_user_dropdown").removeClass("showMenuDropDown");
          $(".loggedin_user").removeClass("showMenuDropDown");
        }
      }
    });

    $(document).on("click", ".relsify_tab_4_nav_link", function (e) {
      e.preventDefault();
      if ($(this).hasClass("disabled-link")) {
        return;
      } else {
        $(".relsify_tab_4_nav .relsify_tab_4_nav_link").removeClass("active");
        $(this).addClass("active");

        let TabId = $(this).attr("tab-id");

        $(".relsify_tab_4_content .relsify_tab_4_content_pan").removeClass(
          "active"
        );
        $(".relsify_tab_4_content #" + TabId).addClass("active");
      }
    });

    $("#app").on("click", ".open_roles_grid_item_header", function () {
      if ($(this).parent().hasClass("active")) {
        $(this).next().slideUp();
        $(this).parent().removeClass("active");
      } else {
        $(".open_roles_grid_item").removeClass("active");
        $(".open_roles_grid_item_body").slideUp();
        $(this).parent().addClass("active");
        $(this).next().slideDown();
      }
    });
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  /* transform: scale(0.9); */
}

.carousel__prev,
.carousel__next {
  background-color: var(--vc-clr-white);
  border-radius: var(--vc-nav-width);
  width: var(--vc-nav-width);
  height: var(--vc-nav-width);
  text-align: center;
  font-size: calc(var(--vc-nav-width) * 2 / 3);
  padding: 0;
  color: var(--vc-clr-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border: 0;
  cursor: pointer;
}

.carousel__prev:focus,
.carousel__next:focus {
  outline: none;
}

.carousel__prev {
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
}

.carousel__next {
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
}

.productDetailss .carousel__prev {
  left: 20px;
}

.productDetailss .carousel__next {
  right: 20px;
}

:root {
  /* Color */
  --vc-clr-primary: #18856f;
  --vc-clr-secondary: #cacaca;
  --vc-clr-white: #ffffff;

  /* Icon */
  --vc-icn-width: 1.2em;

  /* Navigation */
  --vc-nav-width: 30px;
  --vc-nav-color: #ffffff;
  --vc-nav-background-color: var(--vc-clr-primary);

  /* Pagination */
  --vc-pgn-width: 5px;
  --vc-pgn-height: 9px;
  --vc-pgn-margin: 5px;
  --vc-pgn-border-radius: 0;
  --vc-pgn-background-color: var(--vc-clr-secondary);
  --vc-pgn-active-color: var(--vc-clr-primary);
}
.carousel__icon {
  width: var(--vc-icn-width);
  height: var(--vc-icn-width);
  fill: currentColor;
}
.carousel__slide {
  scroll-snap-stop: auto;
  flex-shrink: 0;
  margin: 0;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel {
  position: relative;
  text-align: center;
  box-sizing: border-box;
}

.productDetailss .carousel {
  width: 100%;
  height: 100% !important;
}

.productDetailss .carousel__viewport {
  width: 100%;
  height: 100% !important;
}

.carousel * {
  box-sizing: border-box;
}

.carousel__track {
  display: flex;
  margin: 0;
  padding: 0;
  position: relative;
}

.productDetailss .carousel__track {
  height: 100% !important;
}

.carousel__viewport {
  overflow: hidden;
}
.carousel__pagination {
  display: flex;
  justify-content: center;
  list-style: none;
}
.carousel__pagination-button {
  margin: var(--vc-pgn-margin);
  width: var(--vc-pgn-width);
  height: var(--vc-pgn-height);
  border-radius: var(--vc-pgn-height);
  border: 0;
  cursor: pointer;
  background-color: var(--vc-pgn-background-color);
}
.carousel__pagination-button:focus {
  outline: none;
}

.carousel__pagination-button--active {
  background-color: var(--vc-pgn-active-color);
}

.productSlide {
  width: 100%;
  /* display: flex !important;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; */
  /* flex-wrap: wrap; */
  /* overflow-x: scroll; */

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  padding: 0px 50px 30px;
  margin-bottom: 40px;
}

.productSlide::-webkit-scrollbar {
  width: 0px;
}

@keyframes bounce { 
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
  40% {transform: translateY(-30px);} 
  60% {transform: translateY(-15px);} 
}

@media screen and (max-width: 1450px) {
  .productSlide {
    /* grid-template-columns: repeat(3, 1fr); */
    padding: 0px 30px 30px;
  }
}

@media screen and (max-width: 1250px) {
  .productSlide {
    grid-template-columns: repeat(3, 1fr);
    padding: 0px 30px 30px;
  }
}

@media screen and (max-width: 950px) {
  .productSlide {
    grid-template-columns: repeat(2, 1fr);
    padding: 0px 30px 30px;
  }
}

@media screen and (max-width: 650px) {
  .productSlide {
    grid-template-columns: repeat(2, 1fr);
    padding: 0px 10px 30px;
    gap: 30px 10px;
  }
}

@media screen and (max-width: 350px) {
  .productSlide {
    grid-template-columns: 1fr;
    padding: 0px 10px 0px;
    gap: 30px 0px;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
  }
}
</style>
