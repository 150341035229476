<template>
  <div class="main_page_oauth_socail_div">
    <!-- <h3>LOG<span class="yellow">IN</span></h3> -->
    <div class="main_page_oauth_other">
      <h4>OR</h4>
      <hr />
    </div>

    <div class="main_page_oauth_socials">
      <a
        href="javascript:;"
        @click="handleClickSignIn"
        class="main_page_oauth_socials_box"
      >
        <img src="@/assets/img/googlee.jpg" alt="" />
        <span>Sign in With Google</span>
      </a>
    </div>
  </div>
</template>

<script>
import "@/assets/css/style.css";
import { mapActions } from "vuex";

export default {
  props: ["signInType"],

  data() {
    return {
      // client_id is the only required property but you can add several more params, full list down bellow on the Auth api section
      params: {
        client_id:
          "193087593348-2o7b9pp460bt0ihc0i44et2ci58ipo3o.apps.googleusercontent.com",
      },
      // only needed if you want to render the button with the google ui
      renderParams: {
        width: 250,
        height: 50,
        longtitle: true,
      },
      loginUser: null,
    };
  },

  methods: {
    ...mapActions([
      "saveGoogleDetailsToDB",
      "setAlertpopUp",
      "setPageLoading",
      "setLoginModal",
    ]),

    handleClickSignIn: async function () {
      try {
        const googleUser = await this.$gAuth.signIn();
        if (!googleUser) {
          return null;
        }

        this.loginUser = this.$gAuth.instance.currentUser
          .get()
          .getAuthResponse();
        this.setLoginModal({
          status: false,
          payload: null,
        });

        this.setPageLoading(true);
        this.saveGoogleDetailsToDB(this.loginUser)
          .then((data) => {
            if (data.data.error) {
              this.setPageLoading(false);
              this.setAlertpopUp({
                status: true,
                title: "Login Error",
                des: data.data.meta.message,
                type: "error",
                payload: null,
              });
            }

            if (!data.data.error) {
              let dashboardUrl = this.$store.state.DASHBOARD_URL;
              var createGuest = require("cross-domain-storage/guest");
              var bazStorage = createGuest(dashboardUrl);

              console.log(bazStorage);

              bazStorage.set(
                "Appoen",
                data.data.meta.token,
                function (error, data) {
                  // value for the key of 'fizz' will be retrieved from localStorage on www.baz.com
                  error, data;
                  console.log(data);
                }
              );
              bazStorage.set(
                "actForms",
                data.data.data._id,
                function (error, data) {
                  // value for the key of 'fizz' will be retrieved from localStorage on www.baz.com
                  error;
                  data;
                }
              );
              // setTimeout(() => {
              //   location.reload();
              // }, 3300);
            }
          })
          .catch((error) => {
            error;
            this.setAlertpopUp({
              status: true,
              title: "Login Error",
              des: "Error occurred, Please try again",
              type: "error",
              payload: null,
            });
            this.setPageLoading(false);
          });
      } catch (error) {
        //on fail do something
        console.error(error);
        return null;
      }
    },
  },
};
</script>
