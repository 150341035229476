<template>
  <div class="career-banner">
    <div class="career-banner-content">
      <h4 ref="career_banner_header_text"
      :class="{
        'slide-in-up': is_career_banner_header_text_visible
      }"
      style="
        animation-duration: 200ms;
      ">
        A Piece of Real Estate For Just <span class="text-green">$1</span>
      </h4>
      <p ref="career_banner_desc" :class="{
        'slide-in-up': is_career_banner_desc_visible
        }">
        Experience Africa's most profitable asset class made accessible,
        simplified, fractionalized, and easily transferable within 1-5mins!
      </p>
      <div ref="career_banner_buttons"  class="home-button-flex"
        :class="{
          'slide-in-up': is_career_banner_buttons_visible
        }"
        style="
          animation-duration: 1.5s;
        ">
        <button
          @click="$router.push({ name: 'ExploreMarketPlace' })"
        >
          Explore Marketplace
        </button>
      </div>
      <div ref="career_banner_link"  class="download_mobile_app"
        :class="{
          'slide-in-right': is_career_banner_link_visible
        }">
        <i class="bx bxs-cloud-download upload_icon"></i>
        <span>Download on </span>
        <img
          src="@/assets/img/vectors/arrow-right.png"
          style="width: 16px; height: 7px"
          alt=""
        />
        <img
          src="@/assets/img/vectors/appsbagde-1.png"
          style="width: 100px; height: 31px"
          alt=""
        />
        <img
          src="@/assets/img/vectors/appsbagde-2.png"
          style="width: 100px; height: 31px"
          alt=""
        />
      </div>
    </div>
    <div ref="career_banner_image" class="career-banner-img" :class="{
      'bounce-in': is_career_banner_image_visible
    }">
      <img src="@/assets/img/large-small-site.png" alt="" />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  setup() {
    const career_banner_header_text = ref(null)
    // const career_banner_header_text_firstCall = ref(true)
    const is_career_banner_header_text_visible = ref(false)
    const career_banner_header_text_observer = new IntersectionObserver(([entry]) => {
      is_career_banner_header_text_visible.value  = entry.isIntersecting
    })

    const career_banner_desc = ref(null)
    const is_career_banner_desc_visible = ref(false)
    const career_banner_desc_observer = new IntersectionObserver(([entry]) => {
      is_career_banner_desc_visible.value  = entry.isIntersecting
    })

    const career_banner_buttons = ref(null)
    const is_career_banner_buttons_visible = ref(false)
    const career_banner_buttons_observer = new IntersectionObserver(([entry]) => {
      is_career_banner_buttons_visible.value  = entry.isIntersecting
    })

    const career_banner_link = ref(null)
    const is_career_banner_link_visible = ref(false)
    const career_banner_link_observer = new IntersectionObserver(([entry]) => {
      is_career_banner_link_visible.value  = entry.isIntersecting
    })

    const career_banner_image = ref(null)
    const is_career_banner_image_visible = ref(false)
    const career_banner_image_observer = new IntersectionObserver(([entry]) => {
      is_career_banner_image_visible.value  = entry.isIntersecting
    })

    return {
      career_banner_header_text,
      is_career_banner_header_text_visible,
      career_banner_header_text_observer,

      career_banner_desc,
      is_career_banner_desc_visible,
      career_banner_desc_observer,

      career_banner_buttons,
      is_career_banner_buttons_visible,
      career_banner_buttons_observer,

      career_banner_link,
      is_career_banner_link_visible,
      career_banner_link_observer,

      career_banner_image,
      is_career_banner_image_visible,
      career_banner_image_observer
    }
  },
  mounted() {
    this.career_banner_header_text_observer.observe(this.career_banner_header_text)
    this.career_banner_desc_observer.observe(this.career_banner_desc)
    this.career_banner_buttons_observer.observe(this.career_banner_buttons)
    this.career_banner_link_observer.observe(this.career_banner_link)
    this.career_banner_image_observer.observe(this.career_banner_image)
  }
};
</script>

<style scoped>

.career-banner {
  background: var(--mainLightGreenColor);
  width: 100%;
  max-height: 600px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  padding: 80px 100px;
}

.career-banner-content {
  width: 45%;
  margin-right: auto;
}

.career-banner h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 61px;
  color: #000000;
  margin-bottom: 30px;
}

.career-banner h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 16.41px;
  line-height: 25px;
  color: #858585;
  margin-bottom: 20px;
}

.career-banner p {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 140.62%;
  color: #363636;
  margin-bottom: 30px;
}

.career-banner img {
  width: 100%;
  height: auto;
  border-radius: 2px;
}

.career-banner-img {
  width: 70% !important;
}

.download_mobile_app img:not(:first-child) {
  cursor: pointer;
}

.career-banner-content button {
  border: none;
  background: var(--mainGreenColor);
  border-radius: 5px;
  padding: 11px 40px;
  color: #fff;
  transition: all 300ms ease-in;
}

.career-banner-content button.light_button {
  background: transparent;
  border: 2px solid var(--mainYellowColor);
  color: #000000;
}

.career-banner-content button:hover {
  background: #fff;
  color: var(--mainGreenColor);
}

.home-button-flex {
  display: flex;
  column-gap: 30px;
}
.download_mobile_app {
  margin-top: 80px;
  display: flex;
  column-gap: 30px;
  align-items: center;
}

.download_mobile_app .upload_icon {
  font-size: 22px;
  color: var(--mainGreenColor);
}

.download_mobile_app .arrow_icon {
  font-size: 22px;
}

@media screen and (max-width: 1400px) {
  .career-banner {
    max-height: 600px;
    padding: 80px;
  }

  .career-banner-content {
    width: 48%;
    margin-right: 30px;
  }
}

@media screen and (max-width: 1270px) {
  .career-banner-content {
    width: 70%;
    margin-right: 30px;
  }

  .career-banner-content button {
    padding: 11px 28px;
  }
}
@media screen and (max-width: 1150px) {
  .career-banner {
    padding: 80px 60px;
  }

  .career-banner h4 {
    font-size: 40px;
    /* margin-bottom: 60px; */
  }

  .career-banner h5 {
    font-size: 14.5px;
  }

  .career-banner p {
    font-size: 16px;
  }
}

@media screen and (max-width: 1000px) {
  .career-banner-content {
    width: 89%;
    margin-right: 30px;
  }

  .career-banner h4 {
    font-size: 35px;
    line-height: 41px;
  }

  .career-banner-content button {
    padding: 8px 18px;
    font-size: 14px;
  }
}
@media screen and (max-width: 900px) {
  .career-banner {
    padding: 60px 35px;
  }

  .career-banner-content {
    width: 95%;
    margin-right: 60px;
  }

  .career-banner h4 {
    font-size: 30px;
  }
}

@media screen and (max-width: 720px) {
  .career-banner {
    padding: 40px 25px;
    min-height: 300px;
    height: 100%;
  }

  .career-banner-content {
    width: 100%;
    margin-right: 40px;
  }

  .career-banner h4 {
    font-size: 25px;
    /* margin-bottom: 60px; */
  }
}

@media screen and (max-width: 700px) {
  .career-banner {
    display: block;
    min-height: unset;
    max-height: unset;
    height: 100%;
  }

  .career-banner-content {
    width: 89%;
    margin: auto;
    text-align: center;
  }

  .career-banner-img {
    width: 70%;
    margin: 100px auto 10px;
  }

  .home-button-flex {
    display: flex;
    justify-content: center;
    column-gap: 30px;
    width: 100%;
  }
  .download_mobile_app {
    display: none;
  }
}

@media screen and (max-width: 580px) {
  .career-banner-content {
    width: 95%;
    margin: auto;
  }

  .career-banner h4 {
    font-size: 27px;
  }

  .career-banner-img {
    width: 80%;
  }
}

@media screen and (max-width: 480px) {
  .career-banner {
    padding: 50px 20px 30px;
  }
  .career-banner h4 {
    font-size: 23px;
    margin-bottom: 30px;
  }

  .career-banner h5 {
    font-size: 13.5px;
  }

  .career-banner p {
    font-size: 15px;
  }

  .career-banner-content button {
    padding: 11px 40px;
    font-size: 13px;
    margin-bottom: 20px;
    width: 100%;
  }

  .home-button-flex {
    display: block;
    column-gap: 30px;
  }

  .career-banner-img {
    width: 89%;
    margin: 50px auto 10px;
  }
}
@media screen and (max-width: 380px) {
  .career-banner-content {
    width: 98%;
    margin: auto;
  }
  .career-banner h4 {
    font-size: 23px;
  }
}
</style>
